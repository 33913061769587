import { ENDPOINTS } from "../endpoints";
import { CycleType, SubscriptionType } from "./userSubscriptionService";

export type GetPaymentHistoryResponse = Array<PaymentData>;

export type PaymentData = {
  plan: SubscriptionType;
  cycle: CycleType;
  cost: number;
  payment_date: string;
  invoice_uri: string;
  plan_name: string;
  credits_amount: number;
};

export const getPaymentHistory = async (headers: {
  Authorization: string;
}): Promise<GetPaymentHistoryResponse> => {
  try {
    const url = new URL(ENDPOINTS.PAYMENT_HISTORY);

    const response = await fetch(url.toString(), {
      method: "GET",
      headers,
    });

    if (!response.ok) {
      throw new Error("Failed to get paymentHistory");
    }

    // Parse the response JSON and cast it to PostOrderResponse
    const data = await response.json();

    // return data;
    return data;
  } catch (error) {
    console.error("Error getting paymentHistory:", error);
    throw error;
  }
};
