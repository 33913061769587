import React from "react";
import { Button, CircularProgress, SxProps } from "@mui/material";
import { customColors } from "../theme";

interface CustomButtonProps {
  text: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  onClick: () => void;
  sx?: SxProps;
  icon?: React.ReactNode;
}

export const CustomButton: React.FC<CustomButtonProps> = ({
  text,
  isDisabled = false,
  isLoading = false,
  onClick,
  icon,
  sx = {},
}) => {
  return (
    <Button
      onClick={onClick}
      fullWidth
      variant="contained"
      color="primary"
      startIcon={icon}
      disabled={isDisabled} // Disable during loading or invalid form
      sx={{
        mb: 2,
        "&.Mui-disabled": {
          backgroundColor: customColors.mediumGray,
          color: customColors.gold,
        },
        "&:hover": {
          backgroundColor: customColors.grayedGold,
        },
        ...sx,
      }}
    >
      {isLoading ? (
        <CircularProgress size={24} style={{ color: "#000" }} />
      ) : (
        text
      )}
    </Button>
  );
};
