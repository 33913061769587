import React, { useEffect, useState, useRef } from "react";
import { Box } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { QUERY_PARAMS } from "../../constants";
import { useGetOrderStatus } from "../../hooks/useGetOrderStatus";
import { useGetOrderResult } from "../../hooks/useGetOrderResult";
import { PhotoDisplay } from "./PhotoDisplay";
import { ErrorComponent } from "../ErrorComponent";
import { GetOrderResultResponse } from "../../api/services/orderResultService";
import PhotoPageLoadingState from "./PhotoPageLoadingState";

const POLL_INTERVAL = 5000; // Polling every 5 seconds for initial orderId status
const MORE_IMAGES_POLL_INTERVAL = 10000; // Polling every 10 seconds for additional images

interface Props {
  orderIdProp?: string;
}

const PhotoPage: React.FC<Props> = ({ orderIdProp }) => {
  const [searchParams] = useSearchParams();

  const orderId = orderIdProp || searchParams.get(QUERY_PARAMS.order) || "";
  const statusAPI = useGetOrderStatus();
  const orderResultAPI = useGetOrderResult();

  const [morePicturesRequestedInDisplay, setMorePicturesRequestedInDisplay] =
    useState<boolean>(false);
  const [orderStatusRequested, setOrderStatusRequested] =
    useState<boolean>(false);

  const [orderPollStarted, setOrderPollStarted] = useState<boolean>(false);
  const orderPollStartedRef = useRef<boolean>(false);

  const [orderResult, setOrderResult] = useState<
    GetOrderResultResponse | undefined
  >(undefined);

  const [numberOfInitialPictures, setNumberOfInitialPictures] =
    useState<number>(0);

  // Polling function for initial orderId status
  const pollOrderStatus = () => {
    statusAPI.handleGetOrderStatus({ orderId: orderId });
  };

  // Polling function for additional images
  const pollOrderResult = () => {
    orderResultAPI.handleGetOrderResult({ orderId: orderId });
  };

  // Polling for initial orderId status and fetching results when ready
  useEffect(() => {
    if (
      !orderStatusRequested &&
      !statusAPI.orderStatus?.is_processed &&
      !statusAPI.isError
    ) {
      pollOrderStatus();
      setOrderStatusRequested(true);
    }

    const intervalId = setInterval(() => {
      if (!statusAPI.isError) pollOrderStatus();
      else {
        clearInterval(intervalId);
      }
    }, POLL_INTERVAL);

    if (statusAPI.orderStatus?.is_processed || statusAPI.isError) {
      clearInterval(intervalId);
      if (!statusAPI.isError)
        orderResultAPI.handleGetOrderResult({ orderId: orderId });
    }

    return () => {
      clearInterval(intervalId);
    }; // Cleanup on unmount
  }, [orderStatusRequested, statusAPI.orderStatus, statusAPI.isError]);

  useEffect(() => {
    const allImagesFilled =
      orderResultAPI.orderResult &&
      orderResultAPI.orderResult.image
        .map((img) => img.image_src)
        .every((src) => src !== "");

    if (
      (morePicturesRequestedInDisplay || !allImagesFilled) &&
      !orderPollStartedRef.current && // Use ref for immediate check
      statusAPI.orderStatus?.is_processed
    ) {
      setNumberOfInitialPictures(orderResult ? orderResult.image.length : 0);
      setOrderPollStarted(true); // Triggers a UI re-render
      orderPollStartedRef.current = true; // Immediate access for logic
    }

    const intervalId2 = setInterval(() => {
      if (
        (morePicturesRequestedInDisplay || !allImagesFilled) &&
        orderPollStartedRef.current && // Use ref for logic
        !orderResultAPI.isError
      ) {
        pollOrderResult();
      } else {
        console.log("Clearing interval for order polling");
        clearInterval(intervalId2);
      }
    }, MORE_IMAGES_POLL_INTERVAL);

    // Cleanup the interval on unmount or when conditions fail
    return () => {
      console.log("Cleaning interval on unmount or dependency change");
      clearInterval(intervalId2);
    };
  }, [morePicturesRequestedInDisplay, statusAPI.orderStatus?.is_processed]);

  // Effect to handle updates when new images are received
  useEffect(() => {
    if (
      !orderResult &&
      orderResultAPI.isSuccess &&
      statusAPI.orderStatus &&
      statusAPI.orderStatus.is_processed
    ) {
      setOrderResult(orderResultAPI.orderResult);
    } else if (
      orderPollStartedRef.current && // Use ref for logic
      orderResultAPI.isSuccess &&
      orderResultAPI.orderResult?.image
    ) {
      const currentImageSrcs = orderResultAPI.orderResult.image.map(
        (img) => img.image_src
      );

      const previousImageSrcs = orderResult
        ? orderResult?.image.map((img) => img.image_src)
        : [];

      // Check if there is at least one new image_src compared to previous
      const hasNewImageSrc = currentImageSrcs.some(
        (src, index) => src !== previousImageSrcs[index]
      );

      // Update if there is a new image_src and stop polling if all image_srcs are filled
      if (hasNewImageSrc) {
        setOrderResult(orderResultAPI.orderResult);
      }
      const allImagesComplete =
        currentImageSrcs.every((src) => src !== "") &&
        orderResultAPI.orderResult.image.length > numberOfInitialPictures;

      if (allImagesComplete) {
        setMorePicturesRequestedInDisplay(false);
        setOrderPollStarted(false); // Triggers UI re-render
        orderPollStartedRef.current = false; // Immediate access for logic
      }
    }
  }, [orderResultAPI.orderResult]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignContent: "center",
        alignItems: "center",
        mb: "50px",
        // p: 2,
      }}
    >
      {orderResult ? (
        <PhotoDisplay
          orderId={orderId}
          result={orderResult}
          morePicturesRequested={morePicturesRequestedInDisplay}
          setMorePicturesRequested={setMorePicturesRequestedInDisplay}
        />
      ) : statusAPI.isError ? (
        <ErrorComponent />
      ) : (
        <PhotoPageLoadingState
          percentage={statusAPI.orderStatus?.percentage}
          timeRemaining={statusAPI.orderStatus?.time_remaining}
        />
      )}
    </Box>
  );
};

export default PhotoPage;
