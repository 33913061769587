import React from "react";
import { Box, Typography, IconButton, useMediaQuery } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Grid from "@mui/material/Grid2";
import { DeleteOutline } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { sampleRooms } from "../Gallery/imageLinks";
import { PLACEHOLDER_IMAGE } from "../../constatnts";
import { useAuth } from "../../hooks/useAuth";
import { Loader } from "../Loader";

interface Props {
  handleRemoveImage: () => void;
  handleImageSelect: (image: string) => void;
  handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedImage?: File | null;
  isCompressing?: boolean;
}

export const ImagePanel: React.FC<Props> = ({
  selectedImage,
  handleRemoveImage,
  handleImageSelect,
  handleFileUpload,
  isCompressing = false,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { user } = useAuth();

  const isLoggedInUser = user && user.emailVerified;

  if (isCompressing) {
    return <Loader />;
  }

  return (
    <Grid
      size={{ xs: 12, md: 8 }}
      sx={{ marginBottom: isMobile && !selectedImage ? "20px" : "10px" }}
    >
      <Box
        sx={{
          border: selectedImage ? "none" : "1px dashed #cfd8e3",
          borderRadius: 2,
          minHeight: isMobile ? "260px" : "320px",
          objectFit: "cover",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          position: "relative",
        }}
      >
        {/* Display the selected image or the default upload prompt */}
        {selectedImage ? (
          <>
            <img
              src={URL.createObjectURL(selectedImage)}
              alt="Selected"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "10px",
              }}
              onError={(e) => {
                (e.target as HTMLImageElement).src = PLACEHOLDER_IMAGE;
              }}
            />
            <IconButton
              onClick={handleRemoveImage}
              sx={{
                position: "absolute",
                top: 10,
                right: 10,
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                },
              }}
            >
              <DeleteOutline />
            </IconButton>
          </>
        ) : (
          <>
            <label htmlFor="file-upload">
              <input
                id="file-upload"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileUpload}
              />
              <IconButton component="span">
                <UploadFileIcon
                  sx={{ fontSize: 40, mb: 1, color: "#cfd8e3" }}
                />
              </IconButton>
            </label>
            <Typography sx={{ color: "#cfd8e3" }}>
              {t("TEXT.UPLOAD_PHOTO.UPLOAD_IMAGE")}
            </Typography>
          </>
        )}
      </Box>

      {/* Hide the example images if an image is selected */}
      {!selectedImage && !isLoggedInUser && (
        <>
          <Box mt={2} display="flex" justifyContent="space-between">
            <Typography sx={{ fontWeight: 500 }}>
              {t("TEXT.UPLOAD_PHOTO.USE_EXAMPLE")}
            </Typography>
          </Box>

          <Box
            mt={1}
            sx={{
              overflowX: "scroll",
              display: "flex",
              flexDirection: "row",
            }}
          >
            {sampleRooms.map((image, index) => (
              <Box
                key={index}
                sx={{
                  maxHeight: "100px",
                  margin: "5px",
                  borderRadius: 1,
                  cursor: "pointer",
                }}
                onClick={() => handleImageSelect(image.url)}
              >
                <img
                  src={image.url}
                  onError={(e) => {
                    (e.target as HTMLImageElement).src = PLACEHOLDER_IMAGE;
                  }}
                  alt={`Example ${index + 1}`}
                  style={{
                    maxWidth: isMobile ? "70px" : "100px",
                    borderRadius: "5px",
                    objectFit: "cover",
                  }}
                />
              </Box>
            ))}
          </Box>
        </>
      )}
    </Grid>
  );
};
