import { useState } from "react";
import { getJWT } from "../utils/getJWT";
import {
  getDownload,
  GetDownloadParams,
  GetDownloadResponse,
} from "../api/services/downloadService";

export const useGetDownload = () => {
  const [download, setDownload] = useState<GetDownloadResponse | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const handleGetDownload = async (
    params: GetDownloadParams
  ): Promise<GetDownloadResponse> => {
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);

    try {
      const token = await getJWT();

      // Check if the JWT token exists
      if (!token) {
        throw new Error("Missing JWT token");
      }

      // Create headers including the JWT token in the Authorization header
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response: GetDownloadResponse = await getDownload(params, headers);

      setDownload(response);
      setIsSuccess(true);

      // Return the response to the caller
      return response;
    } catch (error) {
      setIsError(true);
      throw error; // Ensure the error propagates to the caller
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleGetDownload,
    download,
    isLoading,
    isError,
    isSuccess,
  };
};
