import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import Select from "react-select";
import { allCountries } from "country-region-data";
import { customColors } from "../../theme";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../CustomButton";
import { CustomEmailInput } from "../CustomFields/CustomEmailInput";

interface Props {
  onContinue: (email: string, country: string, region: string) => void;
}

export const CountryRegionForm: React.FC<Props> = ({ onContinue }) => {
  const { t } = useTranslation(); // Translation hook
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState(null);
  const [region, setRegion] = useState("");
  const [regionOptions, setRegionOptions] = useState([]);

  const handleCountryChange = (selectedCountry: any) => {
    setCountry(selectedCountry);
    const regions =
      selectedCountry && selectedCountry.regions
        ? selectedCountry.regions.map((regionArray: string[]) => ({
            label: regionArray[0],
            value: regionArray[0],
          }))
        : [];
    setRegionOptions(regions);
    setRegion("");
  };

  return (
    <Box
      sx={{
        maxWidth: 800,
        margin: { xs: "0px 20px", md: "0 auto" },
        padding: 4,
        borderRadius: 2,
        backgroundColor: customColors.semiDarkGray,
        boxShadow: 5,
        color: "white",
      }}
    >
      <Typography variant="h5" gutterBottom sx={{ color: customColors.gold }}>
        {t("TEXT.CHECKOUT.DETAILS")} {/* Translated text */}
      </Typography>
      <Typography
        variant="body2"
        gutterBottom
        sx={{
          color: customColors.fieldBorderLight,
        }}
      >
        {t("TEXT.CHECKOUT.DESCRIPTION")} {/* Translated text */}
      </Typography>

      <CustomEmailInput email={email} setEmail={setEmail} />

      <Typography
        variant="body2"
        marginTop={2}
        marginBottom={1}
        sx={{ color: customColors.fieldBorderLight }}
      >
        {t("TEXT.CHECKOUT.COUNTRY")} {/* Translated text */}
      </Typography>
      <Select
        options={allCountries.map(([countryName, countryCode, regions]) => ({
          label: countryName,
          value: countryCode,
          regions,
        }))}
        value={country}
        onChange={handleCountryChange}
        placeholder={t("TEXT.CHECKOUT.COUNTRY")}
        styles={{
          control: (styles) => ({
            ...styles,
            backgroundColor: customColors.semiDarkGray,
            borderColor: customColors.fieldBorder,
            "&:hover": { borderColor: customColors.gold },
          }),
          singleValue: (styles) => ({ ...styles, color: "#ffffff" }),
          placeholder: (styles) => ({
            ...styles,
            color: customColors.fieldBorderLight,
          }),
          menu: (styles) => ({
            ...styles,
            backgroundColor: customColors.fieldBackgroundLight,
            color: "#ffffff",
          }),
          option: (styles, { isFocused }) => ({
            ...styles,
            backgroundColor: isFocused
              ? customColors.gold
              : customColors.fieldBackgroundLight,
            color: isFocused ? "#000000" : "#ffffff",
          }),
        }}
      />

      {regionOptions.length > 0 ? (
        <>
          <Typography
            variant="body2"
            marginTop={2}
            marginBottom={1}
            sx={{ color: customColors.fieldBorderLight }}
          >
            {t("TEXT.CHECKOUT.REGION")} {/* Translated text */}
          </Typography>
          <Select
            options={regionOptions}
            value={region ? { label: region, value: region } : null}
            onChange={(selectedRegion: any) => setRegion(selectedRegion.value)}
            placeholder={t("TEXT.CHECKOUT.REGION")}
            isDisabled={!regionOptions.length}
            styles={{
              control: (styles) => ({
                ...styles,
                backgroundColor: customColors.fieldBackground,
                borderColor: customColors.fieldBorder,
                "&:hover": { borderColor: customColors.gold },
              }),
              singleValue: (styles) => ({ ...styles, color: "#ffffff" }),
              placeholder: (styles) => ({
                ...styles,
                color: customColors.fieldBorderLight,
              }),
              menu: (styles) => ({
                ...styles,
                backgroundColor: customColors.fieldBackgroundLight,
                color: "#ffffff",
              }),
              option: (styles, { isFocused }) => ({
                ...styles,
                backgroundColor: isFocused
                  ? customColors.gold
                  : customColors.fieldBackgroundLight,
                color: isFocused ? "#000000" : "#ffffff",
              }),
            }}
          />{" "}
        </>
      ) : (
        <></>
      )}

      {regionOptions.length > 0 && !region && (
        <Typography color="error" variant="body2" marginTop={1}>
          {t("TEXT.CHECKOUT.REGION_REQUIRED")} {/* Translated text */}
        </Typography>
      )}

      <CustomButton
        text={t("NAV.CONTINUE")}
        isDisabled={
          !email || !country || (!regionOptions.length ? false : !region)
        }
        onClick={() => onContinue(email, country || "", region)}
        sx={{ mt: 3 }}
      />
      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
        marginTop={3}
        sx={{ color: customColors.fieldBorderLight }}
      >
        {t("TEXT.CHECKOUT.NOTICE")} {/* Translated footer notice */}
      </Typography>
    </Box>
  );
};

export default CountryRegionForm;
