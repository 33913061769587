import React from "react";
import { Typography } from "@mui/material";
import { customColors } from "../theme";

interface IconTitleProps {
  icon: React.JSX.Element;
  title: string;
  color?: string;
  centered?: boolean;
}

const IconTitle: React.FC<IconTitleProps> = ({
  icon,
  title,
  color = customColors.gold,
  centered = false,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: centered ? "center" : "left",
        marginBottom: "10px",
      }}
    >
      {icon}
      <Typography variant="body1" style={{ color: color, marginLeft: "4px" }}>
        {title}
      </Typography>
    </div>
  );
};

export default IconTitle;
