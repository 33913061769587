import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

import { useMediaQuery } from "@mui/material";

const TermsOfUse: React.FC = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <Container
      maxWidth="md"
      sx={{ padding: isMobile ? "20px" : "60px", mb: "60px" }}
    >
      <Box mt={4} mb={4}>
        <Typography variant="h4" gutterBottom sx={{ marginTop: "40px" }}>
          {t("DOCUMENTS.TERMS_OF_USE.TITLE")}
        </Typography>

        <Typography variant="body1" sx={{ marginTop: "40px" }}>
          {t("DOCUMENTS.TERMS_OF_USE.PARAGRAPH")}
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ marginTop: "40px" }}>
          {t("DOCUMENTS.TERMS_OF_USE.SCOPE")}
        </Typography>
        <Typography variant="body1">
          {t("DOCUMENTS.TERMS_OF_USE.SCOPE_PARAGRAPH")}
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ marginTop: "40px" }}>
          {t("DOCUMENTS.TERMS_OF_USE.CONCLUSION")}
        </Typography>
        <Typography variant="body1">
          {t("DOCUMENTS.TERMS_OF_USE.CONCLUSION_PARAGRAPH")}
        </Typography>

        {/* <Typography variant="h6" gutterBottom sx={{ marginTop: "40px" }}>
          {t("DOCUMENTS.TERMS_OF_USE.PADDLE")}
         </Typography>
        <Typography variant="body1">
          {t("DOCUMENTS.TERMS_OF_USE.PADDLE_PARAGRAPH")}
        </Typography> */}

        <Typography variant="h6" gutterBottom sx={{ marginTop: "40px" }}>
          {t("DOCUMENTS.TERMS_OF_USE.TERM_PAYMENT")}
        </Typography>
        <Typography variant="body1">
          {t("DOCUMENTS.TERMS_OF_USE.TERM_PAYMENT_PARAGRAPH")}
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ marginTop: "40px" }}>
          {t("DOCUMENTS.TERMS_OF_USE.MONEY_BACK")}
        </Typography>
        <Typography variant="body1">
          {t("DOCUMENTS.TERMS_OF_USE.MONEY_BACK_PARAGRAPH")}
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ marginTop: "40px" }}>
          {t("DOCUMENTS.TERMS_OF_USE.DESIGN")}
        </Typography>
        <Typography variant="body1">
          {t("DOCUMENTS.TERMS_OF_USE.DESIGN_PARAGRAPH")}
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ marginTop: "40px" }}>
          {t("DOCUMENTS.TERMS_OF_USE.LIABILITY")}
        </Typography>
        <Typography variant="body1">
          {t("DOCUMENTS.TERMS_OF_USE.LIABILITY_PARAGRAPH")}
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ marginTop: "40px" }}>
          {t("DOCUMENTS.TERMS_OF_USE.COPYRIGHT")}
        </Typography>
        <Typography variant="body1">
          {t("DOCUMENTS.TERMS_OF_USE.COPYRIGHT_PARAGRAPH")}
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ marginTop: "40px" }}>
          {t("DOCUMENTS.TERMS_OF_USE.MISCELLANEOUS")}
        </Typography>
        <Typography variant="body1">
          {t("DOCUMENTS.TERMS_OF_USE.MISCELLANEOUS_PARAGRAPH")}
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ marginTop: "40px" }}>
          {t("DOCUMENTS.TERMS_OF_USE.SALVATORY_CLAUSE")}
        </Typography>
        <Typography variant="body1">
          {t("DOCUMENTS.TERMS_OF_USE.SALVATORY_CLAUSE_PARAGRAPH")}
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ marginTop: "40px" }}>
          {t("DOCUMENTS.TERMS_OF_USE.USAGE_RESTRICTIONS")}
        </Typography>
        <Typography variant="body1">
          {t("DOCUMENTS.TERMS_OF_USE.USAGE_RESTRICTIONS_PARAGRAPH")}
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsOfUse;
