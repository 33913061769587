import { ENDPOINTS } from "../endpoints";
import { CycleType, SubscriptionType } from "./userSubscriptionService";

export interface PaymentParams {
  plan: SubscriptionType;
  cycle: CycleType;
}

export interface PaymentResponse {
  redirect_uri: string;
}

// Adjusted to accept headers
export const createPayment = async (
  { plan, cycle }: PaymentParams,
  headers: { Authorization: string }
): Promise<PaymentResponse> => {
  const endpoint = ENDPOINTS.PAYMENT;

  const payload = {
    plan: plan,
    cycle: cycle,
    method: "card",
  };
  try {
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        ...headers,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error("Failed to get payment");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error getting payment:", error);
    throw error;
  }
};
