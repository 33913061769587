import React, { useState } from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import ImageComparison from "../../Gallery/ImageComparison";
import {
  ArrowBackIos,
  FileUpload,
  InsertPhotoOutlined,
} from "@mui/icons-material";
import { customColors } from "../../../theme";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../hooks/useAuth";
import { userHasSubscription } from "../../../utils/userType";
import { useNavigate } from "react-router-dom";
import { useSubscription } from "../../../context/SubscriptionContext";
import ProcessPhotoComponent from "../../../components/Photos/ProcessPhotoComponent";

const HeroSection: React.FC = () => {
  const { t } = useTranslation();

  const [isEntryState, setIsEntryState] = useState<boolean>(true);
  const navigate = useNavigate();

  const { user } = useAuth();
  const { subscriptionResult, isLoading, isError, refetchSubscription } =
    useSubscription();
  const isSubscribedUser =
    user &&
    user.emailVerified &&
    subscriptionResult &&
    userHasSubscription(subscriptionResult);

  const beforeImg = new Image();
  const afterImg = new Image();
  beforeImg.src =
    "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F90.jpg?alt=media&token=dee8cbe4-0b64-4891-9252-c9aef9b7da96";
  afterImg.src =
    "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F92.jpg?alt=media&token=cd29aa38-efbe-4711-a422-177fb403eb1b";

  const isMobile = useMediaQuery("(max-width: 768px)");
  const image = (
    <Grid
      sx={{
        gridColumn: { xs: "1 / -1", md: "2/5" }, // Full width on mobile, 8 columns for image section
      }}
    >
      <ImageComparison beforeImage={beforeImg} afterImage={afterImg} />
    </Grid>
  );

  const text = (
    <Grid
      sx={{
        gridColumn: { xs: "1 / -1", md: "1 / 2" }, // Full width on mobile, 4 columns on medium and up
        marginRight: "24px",
        width: "100%",
        paddingRight: { xs: "0px", md: "1px" },
      }}
    >
      <Typography variant={isMobile ? "h5" : "h2"} gutterBottom>
        <span style={{ fontWeight: "bold", color: customColors.gold }}>
          {t("TITLE.AI")}{" "}
        </span>
        <span style={{ fontWeight: "bold" }}>{t("TITLE.MAIN")}</span>
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        {t("TEXT.UPLOAD_PROMPT")}
      </Typography>
      {isSubscribedUser ? (
        <Button
          variant="contained"
          sx={{ mt: 2 }}
          style={{ borderRadius: "15px", padding: "10px", width: "100%" }}
          onClick={() => {
            navigate("/myphotos");
          }}
          startIcon={<InsertPhotoOutlined />}
        >
          <strong>{t("BUTTONS.GO_TO_PHOTOS")}</strong>
        </Button>
      ) : (
        <>
          <Button
            variant="contained"
            endIcon={<FileUpload />}
            sx={{ mt: 2 }}
            style={{ borderRadius: "15px", padding: "10px", width: "100%" }}
            onClick={() => {
              setIsEntryState(false);
            }}
          >
            <strong>{t("BUTTONS.UPLOAD_IMAGE")}</strong>
          </Button>
          <Typography
            variant="body2"
            sx={{
              mt: 1,
              width: "100%",
              alignContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {t("TEXT.NO_SIGNUP")}
          </Typography>
        </>
      )}
    </Grid>
  );

  return (
    <Box
      sx={{
        backgroundColor: "transparent",
        color: customColors.white,
        padding: { xs: "0px", md: "30px" },
        alignItems: "center",
        alignContent: "center",
      }}
    >
      {isEntryState ? (
        <Container
          sx={{
            // padding: { xs: "0px", md: "30px" },
            marginTop: { xs: "15px", md: "30px" },
          }}
        >
          <Grid
            container
            columns={12} // Define the number of columns explicitly
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "3fr 4fr" }, // 4 columns for text, 8 for the image on medium and up
              gap: 3, // spacing between grid items
              alignItems: "center",
            }}
          >
            {isMobile ? (
              <>
                {image}
                {text}
              </>
            ) : (
              <>
                {text}
                {image}
              </>
            )}
          </Grid>
        </Container>
      ) : (
        <Box sx={{ maxWidth: "1200px", margin: "0 auto" }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setIsEntryState(true)}
            sx={{ margin: "8px" }}
          >
            <ArrowBackIos fontSize="small" />
            <Typography fontSize={"16px"}>{t("TEXT.BACK")}</Typography>
          </IconButton>
          {/* <UploadPhoto /> */}
          <ProcessPhotoComponent />
        </Box>
      )}
    </Box>
  );
};

export default HeroSection;
