import React, { useRef, useState } from "react";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  useMediaQuery,
  Modal,
} from "@mui/material";
import {
  ChevronLeft,
  ChevronRight,
  DownloadRounded,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid2";
import { customColors } from "../../theme";
import { PLACEHOLDER_IMAGE } from "../../constatnts";
import ImageWithLoader from "../ImageWithLoader";
import { useGetDownload } from "../../hooks/useDownload";

export interface ImageProps {
  id: string;
  image_src: string;
}

interface DisplayPanelProps {
  orderId: string;
  result: { image: ImageProps[] };
  displayedImage: ImageProps;
  setDisplayedImage: (image: ImageProps) => void;
  currentImageProps?: { style_name: string; space_name: string };
  isLoggedInUser?: boolean;
}

const DisplayPanel: React.FC<DisplayPanelProps> = ({
  orderId,
  result,
  displayedImage,
  setDisplayedImage,
  currentImageProps,
  isLoggedInUser = false,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const downloadService = useGetDownload();

  const bottomScrollRef = useRef<HTMLDivElement>(null);

  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [pollingInterval, setPollingInterval] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);

  const [loading, setLoading] = useState(false);

  const handleImageClick = (img: ImageProps) => {
    setLoading(true); // Show loader immediately
    setDisplayedImage(img);
  };

  const handleDownload = async (hdImage: boolean) => {
    setDownloadModalOpen(false);
    const response = await downloadService.handleGetDownload({
      orderId,
      imageId: displayedImage.id,
      hdImage,
    });

    if (hdImage && response.hd_processing) {
      setIsProcessing(true);
      const interval = setInterval(async () => {
        const pollResponse = await downloadService.handleGetDownload({
          orderId,
          imageId: displayedImage.id,
          hdImage: true,
        });

        if (pollResponse.image_src) {
          clearInterval(interval);
          setPollingInterval(null);
          setIsProcessing(false);
          triggerDownload(pollResponse.image_src);
        }
      }, 5000);
      setPollingInterval(interval);
    } else if (response.image_src) {
      triggerDownload(response.image_src);
    }
  };

  const triggerDownload = (src: string) => {
    if (!src) {
      console.error("Image source is empty");
      return;
    }

    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = src;

    // Extract the file name from the URL or use a default one
    const fileName =
      src.split("/").pop()?.split("?")[0] || "downloaded_image.jpg";
    link.download = fileName; // Ensure the `download` attribute is set

    // Append the anchor to the document and trigger a click event
    document.body.appendChild(link);
    link.click();

    // Remove the anchor from the document
    document.body.removeChild(link);
  };

  const closeModal = () => {
    setDownloadModalOpen(false);
  };

  const scrollToEnd = () => {
    if (bottomScrollRef.current) {
      bottomScrollRef.current.scrollLeft = bottomScrollRef.current.scrollWidth;
    }
  };

  const handlePrevImage = () => {
    const currentIndex = result.image.findIndex(
      (img) => img.image_src === displayedImage.image_src
    );
    const prevIndex =
      currentIndex === 0 ? result.image.length - 1 : currentIndex - 1;
    setDisplayedImage(result.image[prevIndex]);
  };

  const handleNextImage = () => {
    const currentIndex = result.image.findIndex(
      (img) => img.image_src === displayedImage.image_src
    );
    const nextIndex =
      currentIndex === result.image.length - 1 ? 0 : currentIndex + 1;
    setDisplayedImage(result.image[nextIndex]);
  };

  const downloadModal = (
    <Modal
      open={downloadModalOpen}
      onClose={closeModal}
      aria-labelledby="download-options-title"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: customColors.darkGray,
          padding: "20px",
          borderRadius: "8px",
          boxShadow: 24,
        }}
      >
        {/* Close Button */}
        <Button
          onClick={closeModal}
          sx={{
            position: "absolute",
            top: "4px",
            right: "4px",
            minWidth: "30px",
            minHeight: "30px",
            borderRadius: "50%",
            //   backgroundColor: customColors.mediumGray,
            color: customColors.lightGray,
            padding: 0,
            fontSize: "24px",
            //   fontWeight: "bold",
            lineHeight: "1",
            //   "&:hover": {
            //     backgroundColor: customColors.mediumGray,
            //   },
            cursor: "pointer",
          }}
        >
          &times;
        </Button>

        <Typography
          variant="body1"
          id="download-options-title"
          style={{
            marginBottom: "15px",
            textAlign: "center",
            width: "100%",
            //   border: "1px solid red",
          }}
        >
          {t("TEXT.PHOTOS.DOWNLOAD_OPTIONS")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between", // Adjust spacing between the components
            alignItems: "center", // Align components vertically
          }}
        >
          <Button
            variant="outlined"
            onClick={() => handleDownload(false)}
            sx={{ marginRight: "4px" }}
          >
            {t("TEXT.PHOTOS.NORMAL_QUALITY")}
          </Button>
          <Button
            variant="outlined"
            //   color="secondary"
            sx={{ marginLeft: "4px" }}
            onClick={() => handleDownload(true)}
          >
            {t("TEXT.PHOTOS.HD_QUALITY")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );

  return (
    <Grid size={{ xs: 12, md: isLoggedInUser ? 8.5 : 6 }}>
      <Box sx={{ flex: 2, position: "relative" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between", // Adjust spacing between the components
            alignItems: "center", // Align components vertically
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", marginBottom: "20px" }}
          >
            {t("TEXT.PHOTOS.YOUR_RESULTS", { count: result.image.length })}
          </Typography>
          {isLoggedInUser ? (
            <Button
              variant="outlined"
              fullWidth
              className="hoverButton"
              sx={{
                backgroundColor: customColors.lightGray,
                border: customColors.mediumGray,
                cursor: "pointer",
                color: "white",
                padding: "5px 10px",
                borderRadius: "8px",
                fontSize: "12px",
                width: "120px",
                marginBottom: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              startIcon={!isProcessing ? <DownloadRounded /> : null}
              onClick={() => !isProcessing && setDownloadModalOpen(true)} // Prevent multiple clicks during processing
            >
              {isProcessing ? (
                <CircularProgress size={20} sx={{ color: "white" }} /> // Add loader
              ) : (
                t("TEXT.PHOTOS.DOWNLOAD")
              )}
            </Button>
          ) : (
            <></>
          )}
          {downloadModal}
        </Box>

        <Box
          sx={{
            position: "relative",
            width: "100%",
            backgroundColor: "#f0f0f0",
            borderRadius: "8px",
            marginBottom: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            "&:hover .hoverButton": {
              opacity: 1,
            },
            background: "transparent",
          }}
        >
          <ImageWithLoader displayedImage={displayedImage.image_src} />

          <div
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              padding: "5px 10px",
              borderRadius: "12px",
            }}
          >
            {currentImageProps?.style_name} {currentImageProps?.space_name}
          </div>

          {/* Left Arrow Button */}
          <Button
            sx={{
              position: "absolute",
              left: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              width: "40px",
              height: "40px",
              minWidth: "40px",
              borderRadius: "50%",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
            }}
            onClick={handlePrevImage}
          >
            <ChevronLeft />
          </Button>

          {/* Right Arrow Button */}
          <Button
            sx={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              width: "40px",
              height: "40px",
              minWidth: "40px",
              borderRadius: "50%",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
            }}
            onClick={handleNextImage}
          >
            <ChevronRight />
          </Button>
        </Box>

        <Box
          ref={bottomScrollRef}
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginBottom: isMobile ? "2px" : "20px",
            overflowX: "scroll",
            width: "100%",
            maxWidth: "1000px",
            paddingBottom: "12px",
            scrollbarWidth: "thin",
            scrollbarColor: `${customColors.gold} ${customColors.darkGray}`,
            "&::-webkit-scrollbar": {
              height: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: customColors.gold,
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: customColors.darkGray,
            },
          }}
        >
          {result.image.map((img, idx) =>
            img.image_src.length === 0 ? (
              <Box
                key={`placeholder-${idx}`} // Include index in key to help React track changes
                sx={{
                  width: "80px", // Ensures width consistency for loader placeholders
                  height: "60px",
                  minWidth: "80px", // Ensures consistent width
                  border: `1px solid ${customColors.lightGray}`,
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress
                  sx={{
                    animationDuration: "7s",
                    width: "40px", // Optional loader size
                  }}
                />
              </Box>
            ) : (
              <img
                key={`${img.id}-${idx}`} // Use both id and index for unique key
                src={img.image_src}
                onError={(e) => {
                  (e.target as HTMLImageElement).src = PLACEHOLDER_IMAGE;
                }}
                alt={`Result ${idx + 1}`}
                style={{
                  width: "80px",
                  height: "60px",
                  border:
                    img.image_src === displayedImage.image_src
                      ? `2px solid ${customColors.gold}`
                      : "0px",
                  boxShadow:
                    img.image_src === displayedImage.image_src
                      ? "0 0px 6px 4px rgba(235, 196, 127, 0.2)"
                      : "0 0px 6px 4px rgba(77, 208, 225, 0)",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                onClick={() => handleImageClick(img)}
              />
            )
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default DisplayPanel;
