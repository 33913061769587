import React from "react";
import { Box, LinearProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Loader } from "../Loader";
import { customColors } from "../../theme";

interface Props {
  percentage?: number;
  timeRemaining?: number;
}

const PhotoPageLoadingState: React.FC<Props> = ({
  percentage,
  timeRemaining,
}) => {
  const { t } = useTranslation();

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return minutes > 0
      ? `${minutes} minute${minutes > 1 ? "s" : ""} ${remainingSeconds} second${remainingSeconds !== 1 ? "s" : ""}`
      : `${seconds} second${seconds !== 1 ? "s" : ""}`;
  };

  const processingTimeText =
    timeRemaining && timeRemaining > 0
      ? t("TEXT.PHOTOS.TIME_REMAINING", {
          time: formatTime(timeRemaining),
        })
      : "";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: 6,
        p: { xs: "30px", md: "70px" },
        backgroundColor: customColors.semiDarkGray,
        borderRadius: "12px",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          color: customColors.gold,
          mb: 3,
          fontFamily: "Roboto, sans-serif",
          letterSpacing: "0.5px",
        }}
      >
        {t("TEXT.PHOTOS.GOOD_THINGS_TAKE_TIME")}
      </Typography>

      {percentage && percentage < 100 ? (
        <Box
          sx={{
            width: "100%",
            maxWidth: "600px",
            margin: "0 auto",
            mb: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: "20px",
          }}
        >
          <LinearProgress
            sx={{
              flexGrow: 1,
              backgroundColor: customColors.mediumGray,
              borderRadius: "10px",
              position: "relative",
              overflow: "hidden",
              "&::before": {
                content: '""',
                position: "absolute",
                left: 0,
                top: 0,
                height: "100%",
                width: "100%",
                background: `linear-gradient(90deg, transparent, ${"#fff"}, transparent)`,
                animation: "shimmer 1.5s infinite",
              },
              "& .MuiLinearProgress-bar": {
                backgroundColor: customColors.gold,
                transition: "width 0.5s ease-in-out",
              },
              height: "12px",
              "@keyframes shimmer": {
                "0%": { transform: "translateX(-100%)" },
                "100%": { transform: "translateX(100%)" },
              },
            }}
            value={percentage}
            variant="determinate"
          />

          <Typography
            variant="h6"
            sx={{
              ml: 3,
              color: customColors.gold,
              fontWeight: "bold",
              fontSize: "1.25rem",
            }}
          >
            {t("TEXT.PHOTOS.PERCENTAGE_COMPLETE", {
              percentage: percentage,
            })}
          </Typography>
        </Box>
      ) : (
        <Loader maxHeigthMobile="150px" maxHeigth="200px" />
      )}

      <Typography
        variant="body1"
        sx={{
          textAlign: "center",
          maxWidth: "600px",
          mb: 4,
          color: "white",
          fontFamily: "Roboto, sans-serif",
          lineHeight: "1.6",
          letterSpacing: "0.3px",
        }}
      >
        {t("TEXT.PHOTOS.PROCESSING_MESSAGE")}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          mt: 2,
          fontStyle: "italic",
          color: "white",
          fontFamily: "Roboto, sans-serif",
        }}
      >
        {processingTimeText}{" "}
      </Typography>
    </Box>
  );
};

export default PhotoPageLoadingState;
