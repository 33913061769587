import styled from "@emotion/styled";
import {
  Card,
  Box,
  Typography,
  Divider,
  Button,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { customColors } from "../../theme";
import {
  AttachMoneyOutlined,
  CancelOutlined,
  UpgradeSharp,
} from "@mui/icons-material";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUserSubscription } from "../../hooks/useUserSubscription";
import { Loader } from "../Loader";
import { ErrorComponent } from "../ErrorComponent";
import {
  CycleType,
  GetUserSubscriptionResponse,
} from "../../api/services/userSubscriptionService";
import { useManagePayment } from "../../hooks/useMangePayment";
import { useCancelPayment } from "../../hooks/useCancelPayment";
import { usePaymentHistory } from "../../hooks/usePaymentHistory";
import { PaymentHistory } from "./PaymentHistory";

const BillingCard = styled(Card)(({ theme }) => ({
  padding: "20px",
  borderRadius: "12px",
  marginTop: "20px",
  backgroundColor: customColors.semiDarkGray,
  color: "#fff",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
  border: "1px solid " + customColors.lightGray,
}));

const ProgressWrapper = styled(Box)(({ theme }) => ({
  marginTop: "10px",
  marginBottom: "10px",
}));

const Settings: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const managePaymentAPi = useManagePayment();
  const cancelPaymentAPI = useCancelPayment();

  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const userSubscriptionApi = useUserSubscription();
  const paymentHistoryApi = usePaymentHistory();
  if (
    !userSubscriptionApi.subscriptionResult &&
    !userSubscriptionApi.isError &&
    !userSubscriptionApi.isLoading
  ) {
    userSubscriptionApi.handleUserSubscription();
  }

  if (
    !paymentHistoryApi.paymentHistoryResult &&
    !paymentHistoryApi.isError &&
    !paymentHistoryApi.isLoading
  ) {
    paymentHistoryApi.handlePaymentHistory();
  }

  const userSettings: GetUserSubscriptionResponse | undefined =
    userSubscriptionApi.subscriptionResult;

  useEffect(() => {
    if (
      !managePaymentAPi.isLoading &&
      !managePaymentAPi.isError &&
      managePaymentAPi.redirectURL
    ) {
      window.location.href = managePaymentAPi.redirectURL;
      // window.open(paymentAPI.redirectURL);
    }
  }, [managePaymentAPi.redirectURL]);

  const handleCancelSubscription = async () => {
    await cancelPaymentAPI.handleCancelPayment();
  };

  return userSubscriptionApi.isLoading ||
    managePaymentAPi.isLoading ||
    managePaymentAPi.redirectURL ? (
    <Loader />
  ) : userSubscriptionApi.isError ? (
    <ErrorComponent />
  ) : (
    <Box p={4} maxWidth={1400} mx="auto">
      <Typography variant="h4" gutterBottom>
        {t("TEXT.SETTINGS.TITLE")}
      </Typography>

      <BillingCard>
        <Typography variant="h6">{t("TEXT.SETTINGS.BILLING_TITLE")}</Typography>
        <Typography variant="body2" gutterBottom>
          {t("TEXT.SETTINGS.BILLING_DESCRIPTION")}
        </Typography>

        <Divider sx={{ marginY: 2, backgroundColor: customColors.lightGray }} />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            border: "1px solid " + customColors.lightGray,
            padding: "10px",
            borderRadius: "12px",
          }}
        >
          <Typography variant="body1">
            {t("TEXT.SETTINGS.NEXT_PAYMENT")}{" "}
            <strong>{`$${userSettings?.cost}.00`}</strong>
          </Typography>
          <Button
            variant="outlined"
            startIcon={<AttachMoneyOutlined />}
            onClick={() => managePaymentAPi.handleManagePayment()}
          >
            {t("TEXT.SETTINGS.MANAGE_PAYMENT")}
          </Button>
        </Box>
      </BillingCard>

      <BillingCard>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
            {t("TEXT.SETTINGS.PLAN." + userSettings?.plan)}{" "}
            {t("TEXT.SETTINGS.PLAN_TITLE")}
          </Typography>
          <Box>
            <Button
              variant="outlined"
              color="error"
              startIcon={<CancelOutlined />}
              sx={{ mr: 1 }}
              onClick={() => setOpenCancelDialog(true)}
            >
              {t("TEXT.SETTINGS.CANCEL")}
            </Button>
            <Button
              variant="outlined"
              onClick={() => navigate(`/pricing`)}
              startIcon={<UpgradeSharp />}
            >
              {t("TEXT.SETTINGS.UPGRADE")}
            </Button>
          </Box>
        </Box>

        <Divider sx={{ marginY: 2, backgroundColor: customColors.lightGray }} />

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography
              variant="body2"
              display="flex"
              alignItems="center"
              sx={{ mb: 1 }}
            >
              <Box
                component="span"
                sx={{ display: "inline-flex", alignItems: "center", mr: 1 }}
              >
                📸
              </Box>
              {t("TEXT.SETTINGS.PHOTO_LIMIT", {
                total: userSettings?.total_credits_per_month,
              })}
            </Typography>

            <Typography
              variant="body2"
              display="flex"
              alignItems="center"
              sx={{ mb: 1 }}
            >
              <Box
                component="span"
                sx={{ display: "inline-flex", alignItems: "center", mr: 1 }}
              >
                🎨
              </Box>
              {t("TEXT.SETTINGS.ALL_ROOM_TYPES")}
            </Typography>

            <Typography
              variant="body2"
              display="flex"
              alignItems="center"
              sx={{ mb: 1 }}
            >
              <Box
                component="span"
                sx={{ display: "inline-flex", alignItems: "center", mr: 1 }}
              >
                🪑
              </Box>
              {t("TEXT.SETTINGS.FURNITURE_REMOVAL")}
            </Typography>

            <Typography
              variant="body2"
              display="flex"
              alignItems="center"
              sx={{ mb: 1 }}
            >
              <Box
                component="span"
                sx={{ display: "inline-flex", alignItems: "center", mr: 1 }}
              >
                🚀
              </Box>
              {t("TEXT.SETTINGS.UNLIMITED_RENDERS")}
            </Typography>

            <Typography variant="body2" display="flex" alignItems="center">
              <Box
                component="span"
                sx={{ display: "inline-flex", alignItems: "center", mr: 1 }}
              >
                ❌
              </Box>
              {t("TEXT.SETTINGS.NO_WATERMARKS")}
            </Typography>
          </Box>

          <Box sx={{ mr: "40px" }}>
            <Typography variant="body1" align="right">
              {t("TEXT.SETTINGS.NEXT_PAYMENT")}
            </Typography>
            <Typography variant="body1" align="right">
              {dayjs(userSettings?.next_payment_date).format("MMM D, YYYY")}
            </Typography>
            <Typography variant="body1" align="right">
              {userSettings?.cycle === CycleType.MONTHLY
                ? `$${userSettings?.cost}.00` + t("TEXT.SETTINGS.PER_MONTH")
                : `$${userSettings?.cost}.00` + t("TEXT.SETTINGS.PER_YEAR")}
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ marginY: 2, backgroundColor: customColors.lightGray }} />

        <ProgressWrapper>
          <Typography variant="body2">
            {t("TEXT.SETTINGS.USED_PHOTOS", {
              used: userSettings?.used_credits_per_month,
              total: userSettings?.total_credits_per_month,
            })}
          </Typography>
          <LinearProgress
            variant="determinate"
            value={
              userSettings
                ? (userSettings?.used_credits_per_month /
                    userSettings?.total_credits_per_month) *
                  100
                : 0
            }
            sx={{
              height: 16,
              borderRadius: 5,
              mt: "20px",
              backgroundColor: customColors.lightGray,
            }}
          />
        </ProgressWrapper>

        <Typography variant="body2" align="right">
          {t("TEXT.SETTINGS.IMAGE_CREDITS_REFILL", {
            date: dayjs(userSettings?.next_payment_date).format("MMMM D, YYYY"),
          })}
        </Typography>
      </BillingCard>
      <Dialog
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
        PaperProps={{
          sx: {
            backgroundColor: "#333",
          },
        }}
      >
        <DialogTitle>{t("TEXT.SETTINGS.CONFIRM_CANCEL_TITLE")}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: "#fff" }}>
            {t("TEXT.SETTINGS.CONFIRM_CANCEL_MESSAGE")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCancelDialog(false)} color="primary">
            {t("TEXT.SETTINGS.CANCEL_NO")}
          </Button>
          <Button
            onClick={handleCancelSubscription}
            color="error"
            autoFocus
            disabled={cancelPaymentAPI.isLoading} // Disable while loading
          >
            {t("TEXT.SETTINGS.CANCEL_YES")}
          </Button>
        </DialogActions>
      </Dialog>
      <BillingCard>
        <PaymentHistory payments={paymentHistoryApi.paymentHistoryResult} />
      </BillingCard>
    </Box>
  );
};

export default Settings;
