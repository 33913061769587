import React, { useState } from "react";
import { Box } from "@mui/material";

import PhotoPage from "./PhotoPage";
import UploadPhoto from "./UploadPhoto";
import { customColors } from "../../theme";

const ProcessPhotoComponent: React.FC = () => {
  const [currentPage, setCurrentPAge] = useState<"UPLOAD" | "LOAD" | "RESULT">(
    "UPLOAD"
  );

  const [orderId, setOrderId] = useState<string>("");

  const onPhotoSubmited = (orderId: string) => {
    setOrderId(orderId);
    setCurrentPAge("RESULT");
  };

  return (
    <Box
      sx={{
        maxWidth: "1400px",
        // maxHeight: "550px",
        margin: "0 auto",
        backgroundColor: customColors.semiDarkGray,
        borderRadius: "12px",
      }}
    >
      {currentPage === "UPLOAD" ? (
        <UploadPhoto onPhotoSubmited={onPhotoSubmited} />
      ) : (
        <PhotoPage orderIdProp={orderId} />
      )}
    </Box>
  );
};

export default ProcessPhotoComponent;
