export const PATHS = {
  home: "/",
  gallery: "/gallery",
  pricing: "/pricing",
  login: "/login",
  signup: "/signup",
  checkYourEmail: "/check-your-email",
  forgotPassword: "/forgot-password",
  myPhotos: "/myphotos",
  settings: "/settings",
  uploadPhoto: "/upload-photo",
  termsOfUse: "/terms-of-use",
  privacyPolicy: "/privacy-policy",
  checkout: "/checkout",
  photo: "/photo",
  updatePayment: "/update-payment",
  paymentStatus: "/payment-status",
};
