import React, { useState } from "react";
import { Typography, Box, Button, Snackbar, Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MailOutlineRounded } from "@mui/icons-material";
import { sendEmailVerification } from "firebase/auth";
import { auth } from "../../firebaseConfig";
import { useNavigate } from "react-router-dom";

export const CheckYourEmail: React.FC = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "warning" | "info"
  >("info");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const isTooManyAttemptsError = (error: any) => {
    return (
      error?.message === "TOO_MANY_ATTEMPTS_TRY_LATER" ||
      error?.code === "auth/too-many-requests"
    );
  };

  const handleResendVerification = async () => {
    if (auth.currentUser) {
      try {
        await sendEmailVerification(auth.currentUser);
        setSnackbarMessage(t("TEXT.AUTH.VERIFICATION_EMAIL_SENT"));
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } catch (error: any) {
        if (isTooManyAttemptsError(error)) {
          setSnackbarMessage(t("TEXT.AUTH.TOO_MANY_ATTEMPTS_TRY_LATER"));
        } else {
          setSnackbarMessage(t("TEXT.AUTH.ERROR_OCCURRED"));
        }
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    }
  };

  const reloadUser = async () => {
    if (auth.currentUser) {
      try {
        await auth.currentUser.reload(); // Fetch updated user state
        if (auth.currentUser.emailVerified) {
          navigate("/"); // Redirect if verified
        } else {
          setSnackbarMessage(t("TEXT.AUTH.EMAIL_NOT_VERIFIED"));
          setSnackbarSeverity("warning");
          setSnackbarOpen(true);
        }
      } catch (error: any) {
        if (isTooManyAttemptsError(error)) {
          setSnackbarMessage(t("TEXT.AUTH.TOO_MANY_CHECKS"));
        } else {
          setSnackbarMessage(t("TEXT.AUTH.VERIFICATION_CHECK_FAILED"));
        }
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: { xs: "20px", md: "60px", sx: "100px" },
      }}
    >
      <MailOutlineRounded color="primary" sx={{ fontSize: 80 }} />
      <Typography variant="h4" sx={{ mt: 2, textAlign: "center" }}>
        {t("TEXT.AUTH.CHECK_EMAIL_1")}
      </Typography>
      <Typography variant="body1" sx={{ mt: 3, mb: 2, textAlign: "center" }}>
        {t("TEXT.AUTH.CHECK_EMAIL_2")}
      </Typography>
      <Typography variant="body1" sx={{ mt: 1, mb: 2, textAlign: "center" }}>
        {t("TEXT.AUTH.CHECK_EMAIL_3")}
      </Typography>
      <Button
        onClick={reloadUser}
        variant="contained"
        color="primary"
        sx={{ mb: 1 }}
      >
        {t("TEXT.AUTH.I_VERIFIED_MY_EMAIL")}
      </Button>
      <Button onClick={handleResendVerification} variant="text">
        {t("TEXT.AUTH.RESEND_VERIFICATION_EMAIL")}
      </Button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};
