export type ImageComparisonData = {
  beforeURL: string;
  afterURL: string;
  label: string;
};

export type SimpleImageData = {
  url: string;
  id: string;
};

export const sampleRooms: SimpleImageData[] = [
  {
    url: "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/SampleRoomPictures%2Fsample1.jpg?alt=media&token=9d8d3c44-4d02-4cfb-bee5-f3775abbf0d9",
    id: "sample1vsx",
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/SampleRoomPictures%2Fsample2.jpg?alt=media&token=1d4846c8-88f6-4176-9537-b8598b9511df",
    id: "sample2vsx",
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/SampleRoomPictures%2Fsample3.jpg?alt=media&token=f993824e-913e-4694-830f-97ac76b35806",
    id: "sample3vsx",
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/SampleRoomPictures%2Fsample4.jpg?alt=media&token=38c75d4b-597c-4811-a831-0ea09b43a7cc",
    id: "sample4vsx",
  },
];
export const beforeAndAftersGallery: ImageComparisonData[] = [
  {
    beforeURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/Gallery%2Fa1.jpg?alt=media&token=3bbe8c91-9ad2-48eb-9644-316e2bee7040",
    afterURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/Gallery%2Fa2.jpg?alt=media&token=30bef17e-c8d7-4955-b80e-5b2c58e99862",
    label: "Living room - Modern",
  },
  {
    beforeURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/Gallery%2Fb1.jpg?alt=media&token=a1b25717-9ffe-40d9-84d1-1dab0887e063",
    afterURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/Gallery%2Fb2.jpg?alt=media&token=a17956f1-00da-433e-b270-3791c336d82f",
    label: "Bedroom - contemporary",
  },
  {
    beforeURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/Gallery%2Fc1.jpg?alt=media&token=9c343595-e04e-4305-8327-2f14010bcd56",
    afterURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/Gallery%2Fc2.jpg?alt=media&token=fe8f5d55-766b-4480-ae8a-13de8ab1f054",
    label: "Home office",
  },
  {
    beforeURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/Gallery%2Fd1.png?alt=media&token=2ecb31d7-b7d9-4e8a-a32e-caf2fc1f6b83",
    afterURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/Gallery%2Fd2.png?alt=media&token=a62c2990-98be-43f2-b9cb-6b0a60ef862d",
    label: "Living room - modern",
  },
  {
    beforeURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/Gallery%2Fe1.jpg?alt=media&token=339c227c-8a37-4595-941d-3e7cbd165770",
    afterURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/Gallery%2Fe2.jpg?alt=media&token=c2d4b335-7024-4408-847a-e2a34bef18a2",
    label: "Living room - contemporary",
  },
  {
    beforeURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/Gallery%2Ff1.jpg?alt=media&token=5667bb73-b9a7-4521-b8b9-731fb85acb6f",
    afterURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/Gallery%2Ff2.jpg?alt=media&token=3742db6b-88fb-42c6-99e8-e683848e7722",
    label: "Living room - contemporary",
  },
  {
    beforeURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F50.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
    afterURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F51.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
    label: "Bedroom - Contemporary",
  },
  {
    beforeURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F20.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
    afterURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/HomePAgeSteps%2Fstep3.jpeg?alt=media&token=fae7270b-36b5-4a3c-b89c-d228cac7592e",
    label: "Living room - modern",
  },
  {
    beforeURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F30.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
    afterURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F31.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
    label: "Home office",
  },

  // {
  //   beforeURL:
  //     "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F60.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
  //   afterURL:
  //     "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F61.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
  //   label: "Bedroom - Luxury",
  // },
  // {
  //   beforeURL:
  //     "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F80.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
  //   afterURL:
  //     "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F81.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
  //   label: "Living room - Luxury",
  // },
];

// export const beforeAndAftersGallery: ImageComparisonData[] = [
//   //   {
//   //     beforeURL:
//   //       "https://www.virtualstagingai.app/_next/image?url=%2Frender_examples_5%2Fliving-room-8-empty.webp&w=2048&q=65",
//   //     afterURL:
//   //       "https://www.virtualstagingai.app/_next/image?url=%2Frender_examples_5%2Fliving-room-8-modern.webp&w=2048&q=65",
//   //     label: "Living Room - Modern",
//   //   },
//   //   {
//   //     beforeURL:
//   //       "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/Screenshot%202024-09-12%20at%2011.10.59.png?alt=media&token=a66d11c4-46a6-4439-a526-a135cd1f65cd",
//   //     afterURL:
//   //       "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/Virtual%20Staging%20AI%20-%20Screenshot-2024-09-12-at-11.10.59%20-%20September%2012%2C%202024%2011_11.png?alt=media&token=324fccdc-a577-43c9-a5d5-2e04764c29bf",
//   //     label: "Bedroom - Modern",
//   //   },
//   {
//     beforeURL:
//       "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F00.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
//     afterURL:
//       "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F02.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
//     label: "Kitchen - Modern",
//   },
//   {
//     beforeURL:
//       "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F10.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
//     afterURL:
//       "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F11.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
//     label: "Living room - furniture removal",
//   },
//   {
//     beforeURL:
//       "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F20.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
//     afterURL:
//       "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F21.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
//     label: "Dining room - modern",
//   },
//   {
//     beforeURL:
//       "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F30.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
//     afterURL:
//       "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F31.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
//     label: "Home office",
//   },
//   {
//     beforeURL:
//       "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F40.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
//     afterURL:
//       "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F41.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
//     label: "Kids room",
//   },
//   {
//     beforeURL:
//       "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F50.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
//     afterURL:
//       "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F51.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
//     label: "Bedroom - Luxury",
//   },
//   {
//     beforeURL:
//       "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F60.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
//     afterURL:
//       "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F61.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
//     label: "Bedroom - Luxury",
//   },
//   {
//     beforeURL:
//       "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F80.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
//     afterURL:
//       "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F81.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
//     label: "Living room - Luxury",
//   },
// ];
