import React from "react";
import { CssBaseline } from "@mui/material";
import Header from "./Header/Header";
import StatsSection from "./StatsSection/StatsSection";
import ReviewSlider from "./ReviewSlider/ReviewSlider";
import GalleryPreview from "../Gallery/GalleryPreview";
import HowItWorks from "../HowITWorks";
import WhyUs from "../WhyUs";
import FAQsComponent from "../FAQsComponent";
import { useAuth } from "../../hooks/useAuth";
import { useSubscription } from "../../context/SubscriptionContext";
import { Loader } from "../Loader";

const Home: React.FC = () => {
  const { user } = useAuth();

  const userSubscriptionApi = useSubscription();
  return userSubscriptionApi.isLoading ? (
    <Loader />
  ) : (
    <div style={{ width: "100%" }}>
      <CssBaseline />
      <Header />
      <StatsSection />
      <GalleryPreview />
      <ReviewSlider />
      <HowItWorks />
      <WhyUs />
      <FAQsComponent />
    </div>
  );
};

export default Home;
