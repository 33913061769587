import React from "react";
import { Box, Typography, styled, Switch } from "@mui/material";

import { customColors } from "../../theme";
import { t } from "i18next";

import { CycleType } from "../../api/services/userSubscriptionService";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 50,
  height: 26,
  padding: 0,
  display: "flex",
  "& .MuiSwitch-switchBase": {
    padding: 3,
    color: "#fff",
    "&.Mui-checked": {
      transform: "translateX(24px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        background: "linear-gradient(45deg, #4DD0E1, #4DD0E1)",
        opacity: 1,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 20,
    height: 20,
    boxShadow: "0 3px 5px rgba(255,255,255,.3)",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: customColors.lightGray,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

interface PricingToggleProps {
  billing: CycleType;
  setBilling: React.Dispatch<React.SetStateAction<CycleType>>;
  isMobile: boolean;
}

export const PricingToggle: React.FC<PricingToggleProps> = ({
  billing,
  setBilling,
  isMobile,
}) => {
  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBilling(event.target.checked ? CycleType.YEARLY : CycleType.MONTHLY);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        padding: "0px 0px",
        borderRadius: "20px",
        // margin: "15px 0px 15px 15px ",
        minWidth: "300px",
      }}
    >
      <Typography
        sx={{
          color: billing === CycleType.MONTHLY ? "#fff" : "#888",
          fontWeight: billing === CycleType.MONTHLY ? 700 : 400,
          marginRight: "15px",
        }}
      >
        {t("TEXT.PRICING.MONTHLY.TITLE")}
      </Typography>

      <CustomSwitch
        checked={billing === CycleType.YEARLY}
        onChange={handleSwitchChange}
      />

      <Box display="flex" alignItems="center">
        <Typography
          sx={{
            color: billing === CycleType.YEARLY ? "#fff" : "#888",
            fontWeight: billing === CycleType.YEARLY ? 700 : 400,
            marginRight: "8px",
            marginLeft: "15px",
          }}
        >
          {t("TEXT.PRICING.YEARLY.TITLE")}
        </Typography>
        <Box
          sx={{
            backgroundColor: "rgba(77, 208, 225, 0.2)",
            color: "#4DD0E1",
            padding: "4px 8px",
            borderRadius: "10px",
            fontWeight: 700,
            fontSize: "12px",
          }}
        >
          {t("TEXT.PRICING.40_OFF")}
        </Box>
      </Box>
    </Box>
  );
};
