import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Box, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Google as GoogleIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  browserLocalPersistence,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { auth, googleProvider } from "../../firebaseConfig";
import { CustomButton } from "../CustomButton";
import { useSubscription } from "../../context/SubscriptionContext";
import { PATHS } from "../../routes/routes";

export const Login: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { refetchSubscription } = useSubscription();

  // Form state
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Validation state
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Form validation: only enable button if email and password are valid
  const isFormValid = email && password && isEmailValid && isPasswordValid;

  // Reload user on page visit
  useEffect(() => {
    const updateUserState = async () => {
      if (auth.currentUser) {
        try {
          await auth.currentUser.reload();
          if (auth.currentUser.emailVerified) {
            refetchSubscription();
            navigate("/");
          }
        } catch (error) {
          console.error("Failed to reload user:", error);
        }
      }
    };
    updateUserState();
  }, [navigate, refetchSubscription]);

  const handleSubmit = async () => {
    setLoading(true);
    setErrorMessage(""); // Reset error message

    if (!isFormValid) return;

    try {
      // Set persistence to local (users stay logged in)
      await setPersistence(auth, browserLocalPersistence);

      // Firebase login logic
      await signInWithEmailAndPassword(auth, email, password);

      await auth.currentUser?.reload(); // Reload user state after login
      if (auth.currentUser?.emailVerified) {
        refetchSubscription();
        navigate("/");
      } else {
        setErrorMessage(t("TEXT.AUTH.EMAIL_NOT_VERIFIED")); // Show warning if not verified
      }
    } catch (error: any) {
      if (error.code === "auth/wrong-password") {
        setErrorMessage(t("TEXT.AUTH.WRONG_PASSWORD"));
      } else if (error.code === "auth/user-not-found") {
        setErrorMessage(t("TEXT.AUTH.USER_NOT_FOUND"));
      } else {
        setErrorMessage(t("TEXT.AUTH.LOGIN_FAILED"));
      }
    } finally {
      setLoading(false);
    }
  };

  // Handle Google sign-in
  const handleGoogleSignIn = async () => {
    try {
      setLoading(true);

      // Set persistence to local (users stay logged in)
      await setPersistence(auth, browserLocalPersistence);

      await signInWithPopup(auth, googleProvider);
      await auth.currentUser?.reload(); // Reload user state after login
      if (auth.currentUser?.emailVerified) {
        refetchSubscription();
        navigate("/");
      } else {
        setErrorMessage(t("TEXT.AUTH.EMAIL_NOT_VERIFIED")); // Show warning if not verified
      }
    } catch (error) {
      console.error("Google Sign-In error", error);
    } finally {
      setLoading(false);
    }
  };

  // Validate email format
  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(email));
  };

  // Validate password (at least 6 characters)
  const validatePassword = (password: string) => {
    setIsPasswordValid(password.length >= 6);
  };

  // Handle email input change
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    validateEmail(value);
  };

  // Handle password input change
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);
    validatePassword(value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: { xs: "20px", md: "60px", sx: "100px" },
      }}
    >
      <Typography component="h1" variant="h5">
        {t("TEXT.AUTH.LOGIN_TITLE")}
      </Typography>
      <Typography variant="subtitle1" sx={{ mt: 1, mb: 2 }}>
        {t("TEXT.AUTH.LOGIN_SUBTITLE")}
      </Typography>
      {errorMessage && (
        <Typography color="error" sx={{ mb: 2 }}>
          {errorMessage}
        </Typography>
      )}
      <Box component="form" sx={{ width: "100%", maxWidth: 400 }}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label={t("TEXT.AUTH.EMAIL")}
          value={email}
          onChange={handleEmailChange}
          error={!isEmailValid}
          helperText={!isEmailValid ? t("TEXT.AUTH.INVALID_EMAIL") : ""}
          autoComplete="email"
          autoFocus
          sx={{
            input: { color: "white" }, // Text color inside the input
            label: { color: "white" }, // Label text color
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "white", // Border color
              },
              "&:hover fieldset": {
                borderColor: "white", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "white", // Border color when focused
              },
            },
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label={t("TEXT.AUTH.PASSWORD")}
          type="password"
          value={password}
          onChange={handlePasswordChange}
          error={!isPasswordValid}
          helperText={!isPasswordValid ? t("TEXT.AUTH.WEAK_PASSWORD") : ""}
          autoComplete="current-password"
          sx={{
            input: { color: "white" }, // Text color inside the input
            label: { color: "white" }, // Label text color
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "white", // Border color
              },
              "&:hover fieldset": {
                borderColor: "white", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "white", // Border color when focused
              },
            },
          }}
        />
        <Link
          href="/forgot-password"
          variant="body2"
          sx={{ display: "block", textAlign: "right", mb: 2 }}
        >
          {t("TEXT.AUTH.FORGOT_PASSWORD")}
        </Link>

        <CustomButton
          text={t("NAV.LOGIN")}
          isDisabled={!isFormValid || loading}
          isLoading={loading}
          onClick={handleSubmit}
        />

        <Button
          fullWidth
          variant="outlined"
          startIcon={<GoogleIcon />}
          onClick={handleGoogleSignIn}
          disabled={loading} // Disable Google Sign-In button during loading
          sx={{ mb: 2 }}
        >
          {t("TEXT.AUTH.CONTINUE_WITH_GOOGLE")}
        </Button>
        <Typography variant="body2" align="center">
          {t("TEXT.AUTH.DONT_HAVE_ACCOUNT")}{" "}
          <Link href="#" variant="body2" onClick={() => navigate(PATHS.signup)}>
            {t("NAV.SIGNUP")}
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};
