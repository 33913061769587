import { useState } from "react";
import { createOrder, PostOrderResponse } from "../api/services/orderService";
import { getJWT } from "../utils/getJWT";

interface OrderParams {
  image: File;
  removeFurniture: boolean;
  spaceName: string;
  styleId: string;
  widgetId: string;
  isDemoImage: boolean;
}

export const useCreateOrder = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [orderId, setOrderId] = useState<string | null>(null); // Store order_id

  const handleCreateOrder = async (params: OrderParams) => {
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);
    setOrderId(null); // Reset order_id when starting a new request

    try {
      const token = await getJWT();

      // Check if the JWT token exists
      if (!token) {
        throw new Error("Missing JWT token");
      }

      // Create headers including the JWT token in the Authorization header
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      // Pass headers to the API call and get the response
      const response: PostOrderResponse = await createOrder(params, headers);

      // Set success state and store order_id from the response
      setIsSuccess(true);
      setOrderId(response.order_id);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleCreateOrder,
    isLoading,
    isError,
    isSuccess,
    orderId,
  };
};
