import React, { useEffect } from "react";
import { CssBaseline, Container } from "@mui/material";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import Gallery from "./components/Gallery/Gallery";
import Navbar from "./components/Home/Header/Nav";
import { customColors } from "./theme";
import { Login } from "./components/AuthComponents/Login";
import { Register } from "./components/AuthComponents/Register";
import Footer from "./components/Footer/Footer";
import PricingPlan from "./components/Pricing/PricingPage";
import MyPhotos from "./components/Photos/MyPhotos";
import Settings from "./components/Settings/Settings";
import UploadPhoto from "./components/Photos/UploadPhoto";
import { Checkout } from "./components/Checkout/Checkout";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import TermsOfUse from "./components/Legal/TermsOfUse";
import PrivacyPolicy from "./components/Legal/PrivacyPolicy";
import { ForgotPassword } from "./components/AuthComponents/ForgotPassword";
import { CheckYourEmail } from "./components/AuthComponents/CheckYourEmail";
import { printJWT } from "./utils/getJWT";
import PhotoPage from "./components/Photos/PhotoPage";
import { CurrentImageProvider } from "./context/CurrentImageContext";
import { UpdatePaymentDetails } from "./components/Checkout/UpdatePaymentDetails";
import {
  SubscriptionProvider,
  useSubscription,
} from "./context/SubscriptionContext";
import { useAuth } from "./hooks/useAuth";
import { userHasSubscription } from "./utils/userType";
import PaymentStatus from "./components/Pricing/PaymentStatus";
import { PATHS } from "./routes/routes";
import ReactGA from "react-ga4";

const App: React.FC = () => {
  useEffect(() => {
    document.body.style.paddingTop = "env(safe-area-inset-top)";
    //document.body.style.backgroundColor = "#yourBackgroundColor"; // Replace with your desired color
  }, []);

  useEffect(() => {
    ReactGA.initialize("G-4R3LCQ0T94"); // Replace with your Measurement ID
    ReactGA.send("pageview"); // Tracks the initial pageview
  }, []);

  const { user } = useAuth();

  const { subscriptionResult, isLoading, isError, refetchSubscription } =
    useSubscription();

  const stripePromise = loadStripe(
    "pk_test_51PyDmRRt2ts7ecPYv7dVbG52WrsSHcKtkWguAoYGefGJdWExUWH6tA6nUMmXV60cYVeozvcTXTiuYGrnjnKc50px00bTDsoCaL"
  );

  const isUserWithSubscription =
    user &&
    user.emailVerified &&
    subscriptionResult &&
    userHasSubscription(subscriptionResult);

  return (
    <>
      <Elements stripe={stripePromise}>
        <CssBaseline />
        <CurrentImageProvider>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
              backgroundColor: customColors.darkGray,
            }}
          >
            <Router>
              <Navbar />
              <Container
                disableGutters
                maxWidth={false}
                sx={{ flex: 1, padding: 0, margin: 0 }}
              >
                <Routes>
                  <Route path={PATHS.home} element={<Home />} />
                  <Route path={PATHS.gallery} element={<Gallery />} />
                  <Route path={PATHS.pricing} element={<PricingPlan />} />
                  <Route path={PATHS.login} element={<Login />} />
                  <Route path={PATHS.signup} element={<Register />} />
                  <Route
                    path={PATHS.checkYourEmail}
                    element={<CheckYourEmail />}
                  />
                  <Route
                    path={PATHS.forgotPassword}
                    element={<ForgotPassword />}
                  />
                  <Route
                    path={PATHS.myPhotos}
                    element={
                      user && user.emailVerified ? <MyPhotos /> : <Home />
                    }
                  />
                  <Route
                    path={PATHS.settings}
                    element={isUserWithSubscription ? <Settings /> : <Home />}
                  />
                  <Route path={PATHS.uploadPhoto} element={<UploadPhoto />} />
                  <Route path={PATHS.termsOfUse} element={<TermsOfUse />} />
                  <Route
                    path={PATHS.privacyPolicy}
                    element={<PrivacyPolicy />}
                  />
                  <Route path={PATHS.checkout} element={<Checkout />} />
                  <Route path={PATHS.photo} element={<PhotoPage />} />
                  <Route
                    path={PATHS.updatePayment}
                    element={<UpdatePaymentDetails />}
                  />
                  <Route
                    path={`${PATHS.paymentStatus}/:status`}
                    element={<PaymentStatus />}
                  />
                </Routes>
              </Container>
            </Router>
            <Footer />
          </div>
        </CurrentImageProvider>
      </Elements>
    </>
  );
};

export default App;
