import React, { useState } from "react";

import { useSearchParams } from "react-router-dom";
import { QUERY_PARAMS } from "../../constants";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { customColors } from "../../theme";
import { PricingToggle } from "../Pricing/PricingToggle";
import { packagesPrices } from "../Pricing/constants";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid2";
import { Payment } from "./Payment";
import CountryRegionForm from "./CountryRegionForm";
import { CycleType } from "../../api/services/userSubscriptionService";

// Your publishable key from Stripe dashboard

export const Checkout = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const billingParam =
    (searchParams.get(QUERY_PARAMS.billing) as unknown as CycleType) ||
    CycleType.MONTHLY;
  const planParam = searchParams.get(QUERY_PARAMS.plan) || "Basic";

  const [billing, setBilling] = useState<CycleType>(
    billingParam === CycleType.MONTHLY || billingParam === CycleType.YEARLY
      ? billingParam
      : CycleType.MONTHLY
  );

  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");

  //const variant = Object.keys(packagesPrices).includes(billing) ? packagesPrices[billing] : packagesPrices[CycleType.MONTHLY]
  const variant = packagesPrices[billing];
  const plan =
    planParam === "basic" ||
    planParam === "standard" ||
    planParam === "professional"
      ? variant[planParam]
      : variant["basic"];

  const [currentPage, setCurrentPage] = useState<"CountryForm" | "Payment">(
    "CountryForm"
  );
  const goNext = () => {
    setCurrentPage("Payment");
  };
  const goBack = () => {
    setCurrentPage("CountryForm");
  };

  const pricingToggle = billingParam === CycleType.MONTHLY && (
    <Box
      sx={{
        pb: { xs: "10px", md: "20px" },
        alignContent: "left",
        alignItems: "left",
      }}
    >
      <PricingToggle billing={billing} setBilling={setBilling} isMobile />
    </Box>
  );

  const planTitle = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start", // Aligns the content to the left within the Box
      }}
    >
      <Typography
        sx={{
          border: `1px solid ${customColors.gold}`,
          display: "inline-block",
          borderRadius: "20px",
          padding: "5px 8px",
          color: customColors.gold,
          fontSize: "13px",
          letterSpacing: "3px",
          fontWeight: 900,
        }}
      >
        {planParam.toUpperCase()}
      </Typography>
    </Box>
  );

  const priceInfo = (
    <Grid
      size={{ xs: 12, md: 2 }}
      sx={{
        display: "flex",
        flexDirection: "column",
        margin: { xs: "0px 20px", md: "20px" },
      }}
    >
      {isMobile ? (
        <Box>{planTitle}</Box>
      ) : (
        <Box>
          {pricingToggle}
          {planTitle}
        </Box>
      )}

      <Typography
        variant="h2"
        sx={{
          margin: "10px 0px",
          fontWeight: "bold",
          color: "white",
          fontSize: "24px",
          textAlign: "left",
        }}
      >
        <span>${plan.price}</span>
        <span
          style={{
            fontSize: "20px",
            marginLeft: "4px",
            position: "relative",
            bottom: "0",
          }}
        >
          {t("TEXT.PRICING.PER_MONTH")}
        </span>

        {billing === CycleType.MONTHLY ? (
          <Typography
            sx={{
              borderRadius: "20px",
              color: customColors.gold,
              fontSize: "13px",
              margin: "0 auto",
              textAlign: "left",
              alignContent: "left",
              letterSpacing: "2px",
              fontWeight: 900,
            }}
          >
            {"Cancel anytime"}
          </Typography>
        ) : (
          <Typography
            fontSize={"16px"}
            align="left"
            color={customColors.grayedGold}
            sx={{ mt: "0", mb: "5px", textAlign: "left" }} // Center text horizontally
          >
            {`Billed as $${plan.billedYearly} yearly`}
          </Typography>
        )}
      </Typography>
      {isMobile && <Box sx={{ mb: "10px" }}>{pricingToggle}</Box>}
    </Grid>
  );
  return (
    <Grid
      container
      sx={{
        width: "100%",
        alignItems: "left",
        pt: { xs: "10px", md: "30px" },
        pb: "30px",
      }}
    >
      <Grid size={{ xs: 12, md: 1 }} sx={{ minWidth: "100px" }}></Grid>
      {priceInfo}
      <Grid size={{ xs: 12, md: 6 }}>
        {currentPage === "CountryForm" ? (
          <CountryRegionForm
            onContinue={(email: string, country: string, region: string) => {
              setEmail(email);
              setCountry(country);
              setRegion(region);
              setCurrentPage("Payment");
            }}
          />
        ) : (
          <Payment />
        )}
      </Grid>
      <Grid size={{ xs: 12, md: 2 }} sx={{ minWidth: "100px" }}></Grid>
    </Grid>
  );
};
