import React from "react";

import Grid from "@mui/material/Grid2";
import { Payment } from "./Payment";

// Your publishable key from Stripe dashboard

export const UpdatePaymentDetails = () => {
  return (
    <Grid
      container
      sx={{
        width: "100%",
        alignItems: "center",
        pt: "30px",
        pb: "30px",
        margin: "0 auto",
        alignContent: "center",
      }}
    >
      <Payment actionText="Update" />
    </Grid>
  );
};
