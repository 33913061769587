import React, { useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import { PLACEHOLDER_IMAGE } from "../constatnts";
import { customColors } from "../theme";
import { Loader } from "./Loader";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface ImageWithLoaderProps {
  displayedImage: string;
}

const ImageWithLoader: React.FC<ImageWithLoaderProps> = ({
  displayedImage,
}) => {
  return (
    <Box
      sx={{
        maxWidth: "100%",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "400px",
      }}
    >
      {/* {(localLoading || isFetchingImageURL) && (
        <Loader maxHeigth="400px" maxHeigthMobile="400px" />
      )} */}
      {/* <img
        src={displayedImage}
        alt="After"
        style={{
          width: "auto",
          height: "auto",
          maxHeight: "600px",
          maxWidth: "100%",
          objectFit: "contain",
          display: localLoading ? "none" : "block",
          borderRadius: "8px",
        }}
        onLoad={() => {
          setLocalLoading(false);
          setLoading(false); // Stop global loader
        }}
        onError={(e) => {
          setLocalLoading(false);
          setLoading(false); // Stop global loader on error
          if (!isFetchingImageURL) {
            (e.target as HTMLImageElement).src = PLACEHOLDER_IMAGE;
          }
        }}
      /> */}

      <LazyLoadImage
        src={displayedImage}
        alt="After"
        effect="blur"
        style={{
          width: "auto",
          height: "auto",
          maxHeight: "600px",
          maxWidth: "100%",
          objectFit: "contain",
          borderRadius: "8px",
        }}
      />
    </Box>
  );
};

export default ImageWithLoader;
